import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Software/Home_Automation/PrimaryBoxMQTT';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
import NavButtons from 'components/Software/Home_Automation/NavButtons';
import ForumBox from 'components/Software/Home_Automation/IOBroker/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IOBroker Home Automation",
  "path": "/Software/Home_Automation/IOBroker/MQTTv5/",
  "dateChanged": "2022-07-18",
  "author": "Mike Polinowski",
  "excerpt": "Empowering the smart home - a vendor and technology agnostic open source automation software for your home.",
  "social": "/images/Search/AU_SearchThumb_ioBroker.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_ioBroker_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "ioBroker"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='IOBroker Home Automation' dateChanged='2022-07-18' author='Mike Polinowski' tag='INSTAR IP Camera' description='Empowering the smart home - a vendor and technology agnostic open source automation software for your home.' image='/images/Search/AU_SearchThumb_ioBroker.png' twitter='/images/Search/AU_SearchThumb_ioBroker.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Home_Automation/IOBroker/' locationFR='/fr/Home_Automation/IOBroker/' crumbLabel="ioBroker" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "home-automation-mqttv5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#home-automation-mqttv5",
        "aria-label": "home automation mqttv5 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Home Automation (MQTTv5)`}</h2>
    <p>{`This guide uses the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTTv5_API/"
      }}>{`MQTTv5 API`}</a>{`. For Full HD cameras please replace the MQTT topics with the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTT_API/"
      }}>{`MQTTv3 API`}</a>{`. If you don't use MQTT in your smarthome system please refer to our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/CGI_Commands/"
      }}>{`HTTP API (CGI) documentation`}</a>{` - the `}<em parentName="p">{`CGI version`}</em>{` of this guide can be found `}<a parentName="p" {...{
        "href": "/en/Software/Home_Automation/IOBroker/"
      }}>{`here`}</a>{`.`}</p>
    <h3 {...{
      "id": "iobroker-home-automation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#iobroker-home-automation",
        "aria-label": "iobroker home automation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IOBroker Home Automation`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#home-automation-mqttv5"
        }}>{`Home Automation MQTTv5`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#iobroker-home-automation"
            }}>{`IOBroker Home Automation`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#introduction"
        }}>{`Introduction`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#how-does-it-work"
        }}>{`How does it work`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-external-sensors"
            }}>{`Use external Sensors`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#activate-nightvision-only-when-motion-is-detected"
            }}>{`Activate Nightvision only when Motion is detected`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#schedule-different-positions"
            }}>{`Schedule different Positions`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-different-detection-areas-for-each-position"
            }}>{`Use different Detection Areas for each Position`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-different-sensitivities-for-day--night"
            }}>{`Use different Sensitivities for Day & Night`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#turn-off-all-cameras-when-you-arrive-home"
            }}>{`Turn off all Cameras when you arrive home`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-external-sensors-to-turn-your-camera-when-motion-is-detected"
            }}>{`Use external Sensors to turn your Camera when Motion is detected`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-external-sensors-to-trigger-alarm-recordings"
            }}>{`Use external Sensors to trigger Alarm Recordings`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#interconnect-your-cameras"
            }}>{`Interconnect your Cameras`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#scan-for-noise-sources"
            }}>{`Scan for Noise Sources`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#manually-deactivate-all-alarm-functions-for-all-your-cameras"
            }}>{`Manually deactivate all Alarm functions for all your Cameras`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#switch-between-pir-and-software-motion-detection"
            }}>{`Switch between PIR and Software Motion Detection`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.iobroker.net/#de/documentation"
      }}>{`ioBroker`}</a>{` is a software solution for integrating various smarthome / IoT (Internet of Things) systems that would remain without ioBroker island solutions in an overall system. An ioBroker system has a modular structure. A variety of adapters allows communication with over 200 different platforms from Amazon Alexa, Google Home to generic MQTT, Z-Wave or Zigbee IoT devices.`}</p>
    <p>{`In the following paragraphs we want to show you how to connect external wireless sensors through the ioBroker system with your INSTAR IP camera, set up time schedules for your surveillance and optimize your camera operation.`}</p>
    <p>{`If you are looking for a deep dive into setting up your ioBroker automation with an INSTAR IP camera, switch to our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/"
      }}>{`FULL GUIDE`}</a>{` instead.`}</p>
    <h2 {...{
      "id": "how-does-it-work",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-does-it-work",
        "aria-label": "how does it work permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How does it work`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
        }}>{`How to Configure the INSTAR MQTT Broker/Client`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
        }}>{`The INSTAR MQTT Service`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTTv5_API/"
        }}>{`The INSTAR MQTTv5 API Documentation`}</a></li>
    </ul>
    <h3 {...{
      "id": "1-use-external-sensors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#1-use-external-sensors",
        "aria-label": "1 use external sensors permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Use external Sensors`}</h3>
    <p>{`Triggering an alarm on the camera by an event registered by your home automation system:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/10e307e54c8bdd599affad72fce54ed0/29007/ioBroker-EN_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACCElEQVQoz22Sy27TQBiF/XI8DE8CQuxZIliwoBISsIFFVdTS9KJe0rShQUnaNL6lcXzP2DMexx+yXVdVxZE+HWlmzpl/pDGqqqKmVp5LlFK0qkjTlJUfYNsWR/1TLNdBCEGnOIpwXRfP85BSNmtGG2219CKWQUoChBpWG3By2Nnb5f33z0zCFFuUzIMEM1qjKgjDCFVouh5Dx3Makjl5cEu+mqKCW1QwQ/k36OiO3J9SpSab1CJyrvGtIaEzoohNMn9KEd3R9RhycU6HWpxR+GNUYFFELnI1Q9/3WToXTOcDinrfG6C9cwr3EGn3UPcXPO0w1HJAy2WzoFObTb6mUgItQjb+kLl9xcy6ovQGyPs+uT+hHL6jOHuNXI2a0q7nsVA+uJgfk0z2SW8OSMa/KRdn/Lm7xHaGzbTEI052vzL68hIuX5N7zwrrG+WipRnfOkJOd1qsw2ai2fiC/b1fxOYJ+AO2v33kx9sXcPWK7H748Nx+c9YQdo8Gq0fmHiP6W4iDTy3nW2SLI5b2hOvRiNg8htkOpz8/8Hf7DdXtFmv7kKzrsHsYxSbhkbL1vBLISlAikDrB9z2SJCZXCRUCdzkj02s08jHTYSgd0VE0HmJnilWeYpojLGfSsFjOcBZTTHtMrgJ0Wf+/gKf5GqPcZDwnUAqhJUKErEVIlsfkMmm8Ruk1/8vV/AMgqjVudfPW9wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/10e307e54c8bdd599affad72fce54ed0/e4706/ioBroker-EN_01.avif 230w", "/en/static/10e307e54c8bdd599affad72fce54ed0/d1af7/ioBroker-EN_01.avif 460w", "/en/static/10e307e54c8bdd599affad72fce54ed0/7f308/ioBroker-EN_01.avif 920w", "/en/static/10e307e54c8bdd599affad72fce54ed0/e1c99/ioBroker-EN_01.avif 1380w", "/en/static/10e307e54c8bdd599affad72fce54ed0/2babf/ioBroker-EN_01.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/10e307e54c8bdd599affad72fce54ed0/a0b58/ioBroker-EN_01.webp 230w", "/en/static/10e307e54c8bdd599affad72fce54ed0/bc10c/ioBroker-EN_01.webp 460w", "/en/static/10e307e54c8bdd599affad72fce54ed0/966d8/ioBroker-EN_01.webp 920w", "/en/static/10e307e54c8bdd599affad72fce54ed0/445df/ioBroker-EN_01.webp 1380w", "/en/static/10e307e54c8bdd599affad72fce54ed0/fad48/ioBroker-EN_01.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/10e307e54c8bdd599affad72fce54ed0/81c8e/ioBroker-EN_01.png 230w", "/en/static/10e307e54c8bdd599affad72fce54ed0/08a84/ioBroker-EN_01.png 460w", "/en/static/10e307e54c8bdd599affad72fce54ed0/c0255/ioBroker-EN_01.png 920w", "/en/static/10e307e54c8bdd599affad72fce54ed0/b1001/ioBroker-EN_01.png 1380w", "/en/static/10e307e54c8bdd599affad72fce54ed0/29007/ioBroker-EN_01.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/10e307e54c8bdd599affad72fce54ed0/c0255/ioBroker-EN_01.png",
              "alt": "IO Broker",
              "title": "IO Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/pushalarm `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "2-activate-nightvision-only-when-motion-is-detected",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2-activate-nightvision-only-when-motion-is-detected",
        "aria-label": "2 activate nightvision only when motion is detected permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Activate Nightvision only when Motion is detected`}</h3>
    <p>{`Activate your cameras night vision according to a schedule or triggered by an external sensor integrated in home automation system:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a6aa0e5c9dc98c29e908598e1eab2d94/29007/ioBroker-EN_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACvklEQVQoz22RS2wTZxSFZ4m6YseKCgTBVuzanvn/sSczE2NPHDMJcXASG4ugRnmAolaAUBuZOCBlURIEoSU8FzwqVU1BVQW0AjVNFRxiCKWVKCCxgUW3rFCrgHjUqT7IAKtmcXSlo6Nzz71HWVZ+gXN3gdTdBeJ/Vll76xWr517x4dy/rCg/RfnpCStn5qmZe0noj6qnte5USd9bwL5TpfH+f6i/PeeDX/5GufoPyvJTs/jOzrLWQ4XA1xX0b26y7nSZ3NQjrGuPSU39hTZxmxUnr+E7c501p2cInL1O7blZVp2axrr0gLrpx/guPUSRwVp0LYIIh1CDAbSPAgR9NaSdBLcqFWamppn88TKb0g2IYC2mFCSsOpK2SX0siqGFKU/+zO2ZChcnvkX5vFhk+MQYDe1ZgqkMsnEjq+sSHDh8hOcvq0yc/4H9IyOMjI4yfHyMTFcnIrMZo6Udv9PM7mKJ+WcvuDI5zZ7SIMoGdwNtuTY0KRCGiW7a1KqCT3fsonLjd8aPncBNOWzr6yPT1sr6xHpc16XJdUmkUnT1befXqTIXvvseLRREUVWVcCiMruvoUqBLiVBV4vE4Xxw4RLapme5sK/l8Hr/f7+mElGhSEhOCFiEZL+2jq3MrKTWCsiiIRqNIXSJ13YP+bkZ868hYBl8dOcjmQoFIJOJpLSnJGgY9us5AJIJbU0NjTDJU+uyt4VLQhKAxbjO4r8jY+CEvYSgcxjAMsq5LPh6nx7LoicVwojp7hgYYGi793/B9wsVXpJubKW7v4MvRvRS2dOL3+ci0tjJ+9CiaqtJkmmyMxbCdBgY/2cLe3b1LJJTybUJNw3ESfJxWKe3sJl8oeEtyuRz9/f0kk0ls20YXErvepruljt725NIny3emiyUFgwGy2U10dHR4vOM4mKaJk0xiWZbHSSnQNBXtTSmvAcClxssn6PSRAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6aa0e5c9dc98c29e908598e1eab2d94/e4706/ioBroker-EN_02.avif 230w", "/en/static/a6aa0e5c9dc98c29e908598e1eab2d94/d1af7/ioBroker-EN_02.avif 460w", "/en/static/a6aa0e5c9dc98c29e908598e1eab2d94/7f308/ioBroker-EN_02.avif 920w", "/en/static/a6aa0e5c9dc98c29e908598e1eab2d94/e1c99/ioBroker-EN_02.avif 1380w", "/en/static/a6aa0e5c9dc98c29e908598e1eab2d94/2babf/ioBroker-EN_02.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a6aa0e5c9dc98c29e908598e1eab2d94/a0b58/ioBroker-EN_02.webp 230w", "/en/static/a6aa0e5c9dc98c29e908598e1eab2d94/bc10c/ioBroker-EN_02.webp 460w", "/en/static/a6aa0e5c9dc98c29e908598e1eab2d94/966d8/ioBroker-EN_02.webp 920w", "/en/static/a6aa0e5c9dc98c29e908598e1eab2d94/445df/ioBroker-EN_02.webp 1380w", "/en/static/a6aa0e5c9dc98c29e908598e1eab2d94/fad48/ioBroker-EN_02.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6aa0e5c9dc98c29e908598e1eab2d94/81c8e/ioBroker-EN_02.png 230w", "/en/static/a6aa0e5c9dc98c29e908598e1eab2d94/08a84/ioBroker-EN_02.png 460w", "/en/static/a6aa0e5c9dc98c29e908598e1eab2d94/c0255/ioBroker-EN_02.png 920w", "/en/static/a6aa0e5c9dc98c29e908598e1eab2d94/b1001/ioBroker-EN_02.png 1380w", "/en/static/a6aa0e5c9dc98c29e908598e1eab2d94/29007/ioBroker-EN_02.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a6aa0e5c9dc98c29e908598e1eab2d94/c0255/ioBroker-EN_02.png",
              "alt": "IO Broker",
              "title": "IO Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`features/nightvision/autoled	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"2"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Activation of the night vision in case of alarm is of course only possible if the camera has an internal PIR sensor for motion detection or if you use the alarm signal of an external sensor in the home automation to arm the camera. The advantage - the camera is inconspicuous at night and the IR light does not attract insects / spiders. Of course, the power consumption is also noticeably reduced.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "3-schedule-different-positions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#3-schedule-different-positions",
        "aria-label": "3 schedule different positions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Schedule different Positions`}</h3>
    <p>{`Scheduled day and night position - guard the front door when I'm not at home but I turn the camera to my carport when I'm back from work:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4e4b964168fa11d284d70889243b7ec4/29007/ioBroker-EN_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC9ElEQVQoz1WR3U9bBRiHz1/gjYlGE5ORKSOhp+3pOT3nQIEyB0NBWAoRx4y1lPJR4kD2kQoiYyBM42eiiSZLXDTqlRdeeDVjYjYLY2PMLQzmWqZzLJlri12F0fac08fs7Ebf5Ml798v7vD/hw1gD89+W8eZAO+9PHGX2aD/vjh5kaqSHoQPN9Af8jEW7mR5p46ORR2jwiMSiYfr2Bwi2txLqDNBcV0XlU48h7ngCYay7icmwk+EX6xkNtTEW6eBQVyOH9z/LkQONHOqsY/b1V4mFA/Q1PUqLVMFLfoV9NQqNsoMGqYLWGgVv2eNoO59ESFy5yOrSIolfF/htKc7VS+dZXoyzsniWxKU5zv38A8tLcaLhblzlu/CrEtKunXzywXuMvDZI5dNleB0V1Fd7eW6PH4H/TAnY2i6Q3dwmByT/uMln333DUvIaAwNRHE4Rt0fmhbZ9ZHObnIkv4KjZTW90kHdOfkpnJIhQyOcxjCKWaZLL/cP8wgVWr1234//e2GAjlyWXThMKBnE6nXgkiZaWFs7+Ms/pH3+iuqoKn8/H863N+HQdoVgsYFmmHWiaJonkGn/dWodi0b56/fIVznz9JZHeCKLDga5rqKrKxLFJDg4NI0kSslel8ply2v21CEXDxCrxEMt66G4Y9krMzXH684/JZG8TjvTicIjoVdWomoamqrjdbhSvF0UUCdT5mDj2BkJ+K8d2Kknh3h070DQMzGIRq1Ri7eICqa07ZPMb9PWEqFfK2VsroSgKmqaj6zoeWcavqcRGjzAx/RbC5t01jPv32E7/jpHfwrJKtrppmHZJmfmvSN9aZuxwP8GuDvpfCVCryyheDU3TkGUPu/c2MRrt4nhsECGfS1EqWRj3s1hGgZKtbtrKmdRtLpwcZjX+PUODEXrCYXpDLyO7HTidLlwuF6L44A06XQ0S4Y49CHdTKdLptE3qAamUTSaTYf3Pm6ysLHMjeZ0vTp1ifHycqalpZmZm/s/b00wdn+TEiVn+BaUdUmxE3OaCAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4e4b964168fa11d284d70889243b7ec4/e4706/ioBroker-EN_03.avif 230w", "/en/static/4e4b964168fa11d284d70889243b7ec4/d1af7/ioBroker-EN_03.avif 460w", "/en/static/4e4b964168fa11d284d70889243b7ec4/7f308/ioBroker-EN_03.avif 920w", "/en/static/4e4b964168fa11d284d70889243b7ec4/e1c99/ioBroker-EN_03.avif 1380w", "/en/static/4e4b964168fa11d284d70889243b7ec4/2babf/ioBroker-EN_03.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4e4b964168fa11d284d70889243b7ec4/a0b58/ioBroker-EN_03.webp 230w", "/en/static/4e4b964168fa11d284d70889243b7ec4/bc10c/ioBroker-EN_03.webp 460w", "/en/static/4e4b964168fa11d284d70889243b7ec4/966d8/ioBroker-EN_03.webp 920w", "/en/static/4e4b964168fa11d284d70889243b7ec4/445df/ioBroker-EN_03.webp 1380w", "/en/static/4e4b964168fa11d284d70889243b7ec4/fad48/ioBroker-EN_03.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4e4b964168fa11d284d70889243b7ec4/81c8e/ioBroker-EN_03.png 230w", "/en/static/4e4b964168fa11d284d70889243b7ec4/08a84/ioBroker-EN_03.png 460w", "/en/static/4e4b964168fa11d284d70889243b7ec4/c0255/ioBroker-EN_03.png 920w", "/en/static/4e4b964168fa11d284d70889243b7ec4/b1001/ioBroker-EN_03.png 1380w", "/en/static/4e4b964168fa11d284d70889243b7ec4/29007/ioBroker-EN_03.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4e4b964168fa11d284d70889243b7ec4/c0255/ioBroker-EN_03.png",
              "alt": "IO Broker",
              "title": "IO Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`features/ptz/preset     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` - `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"7"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "4-use-different-detection-areas-for-each-position",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#4-use-different-detection-areas-for-each-position",
        "aria-label": "4 use different detection areas for each position permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Use different Detection Areas for each Position`}</h3>
    <p>{`In combination with the scheduled day and night position (see above) you can also activate different motion detection areas - in the example the areas 1 & 2 are activated during the day and the areas 3 & 4 at night:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dfbb6cd0f82d365b014a7174794b865c/29007/ioBroker-EN_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAADDklEQVQoz1WPz0/bBQDFv/+A8WLixYuGwMLaQltaKK2ltIxi164gG5FfBUpZaRHMgKGuZBMkARFwicZgTGSJ0ahZsnrCLZMtrRSorFRWWoQii0RqmuJgbLDC4sfYk34uL+/y8nnC2EU9c1+/TL+rmlFPNx+8+ybDfW6G3+7gUnstjjMaLnc5Geg6zUcXnueUXIK7vprO5jrMJcVopScpkYnQycWoJScQ3mkqY8CeR+frWvoaTfQ7a7hYb6SnRkdvXRm9b+gY6ztPb5MFZ8ULVEiyqNHKqXy1AJNKikkpxqgQYywQYcjPQVhfvkcstMh6eJ5fQwFWQkGWgj8R+tnPyqKfmenvuDc/g8veQl52DiUKKdKcV/hkYoy33O2cyM6iWJ6PQavCZCxF4D/8DTw5TLOzf0gaWN3YZPL6V0QebNDe7kIkEZMvk3Gmsoq9/QPu+gOIlSoaGm2MT01S73YgHBwckE6nOTo6Ynd3j9m5INHoamb+8f4jDo/TPHn4kGabDYlEgkwqxWy24J9d4Iebt1GrVCgUCnQGPerCov8b/ktie5v9nRQ8e5bpv4fD+L68hqPNgVgkoqiwEKVSyZXLV+jocJOfJ0YulyLJzuKcXovgW1giEIkzH46xsBRlcXmNpV9iPD0+Jj4/z63Jq+zs/YHd0YZIJKZIVZwxUqhLkav1FJaUoymt4LShgo4LPQjeb25w2zPI9996mbu/Sji2iXd6hvX1OFv3wyT3E+w+/Yvzrc3oCrIp10iRyWTU2tqoPNvMuToHZeYGbM5e7syFEFanvayocxmuGcTWEiS6nCKZ3CIajWUupwLXSG1FuNTjxFZbjdNWhaZAhtaqIXvgRXJbX6LT9RzvD2pJJJIIgRufEu+283HX5zTZAvx4x09ie4NYdI3Hj1IEP+skNuuly+2g1W7H0VKP7GQu9u4GGm9V03Pdxc3ps8zc7efB5jbCa+WnsFZZsVgtWCvNmCxGyvQGfD4fqeSfRCLL/BZf44upKTweD0NDQ5l8r9/D+PA4E6MTjIyMMzZ2lQ9HRvkHOgo9uG+nTs0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dfbb6cd0f82d365b014a7174794b865c/e4706/ioBroker-EN_04.avif 230w", "/en/static/dfbb6cd0f82d365b014a7174794b865c/d1af7/ioBroker-EN_04.avif 460w", "/en/static/dfbb6cd0f82d365b014a7174794b865c/7f308/ioBroker-EN_04.avif 920w", "/en/static/dfbb6cd0f82d365b014a7174794b865c/e1c99/ioBroker-EN_04.avif 1380w", "/en/static/dfbb6cd0f82d365b014a7174794b865c/2babf/ioBroker-EN_04.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dfbb6cd0f82d365b014a7174794b865c/a0b58/ioBroker-EN_04.webp 230w", "/en/static/dfbb6cd0f82d365b014a7174794b865c/bc10c/ioBroker-EN_04.webp 460w", "/en/static/dfbb6cd0f82d365b014a7174794b865c/966d8/ioBroker-EN_04.webp 920w", "/en/static/dfbb6cd0f82d365b014a7174794b865c/445df/ioBroker-EN_04.webp 1380w", "/en/static/dfbb6cd0f82d365b014a7174794b865c/fad48/ioBroker-EN_04.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dfbb6cd0f82d365b014a7174794b865c/81c8e/ioBroker-EN_04.png 230w", "/en/static/dfbb6cd0f82d365b014a7174794b865c/08a84/ioBroker-EN_04.png 460w", "/en/static/dfbb6cd0f82d365b014a7174794b865c/c0255/ioBroker-EN_04.png 920w", "/en/static/dfbb6cd0f82d365b014a7174794b865c/b1001/ioBroker-EN_04.png 1380w", "/en/static/dfbb6cd0f82d365b014a7174794b865c/29007/ioBroker-EN_04.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dfbb6cd0f82d365b014a7174794b865c/c0255/ioBroker-EN_04.png",
              "alt": "IO Broker",
              "title": "IO Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/area1/enable      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area2/enable      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area3/enable      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area4/enable      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "5-use-different-sensitivities-for-day--night",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#5-use-different-sensitivities-for-day--night",
        "aria-label": "5 use different sensitivities for day  night permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`5. Use different Sensitivities for Day & Night`}</h3>
    <p>{`Since there are often different sensitivities needed for the motion detection during day and night conditions, you can also adjust these - in the example to 75% during the day and 25% at night:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dc3f89dc316858dad848ddba49ed8985/29007/ioBroker-EN_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAADCElEQVQozzWP609bBRjGzz/hR4MXsgC2tSjSdVWoipuDD2MSN93G1pYOBjqyuSXTOZg0MA14iTg76xLqZBgzGIlx6wScgkBXwDAvW2BFcISxqAfa0wvr6bn0Z+j0SZ68z/Pll+cVDJeHODu6k5yBRSzjSTaNS1jHYhhGJDaNSeSPSBQMRak/UUfHKSO2HS72H2/hteZW6k94aHznFNWH36TcWY9x60sIz44u4QgGsQclqn6OUzERo+xaDNu4xAuhGPnDEuVBieqDDby8p5jiiiq27nZQttvFc9W1PL3TQemrLjbvq8O8ZRuComikdNB1nXuqzmJcZjkSQ0ymWElpiLJGXFZwOWvYkGdi40YLj+bmctTzLlM3ZvgxNJm9w9M32bX/AEI6nSajqeiaRlrT+SuRQl5Lsq6EDFO3k8QUFXeNC6PJiM1mY0NePp1d5/hfWgYWozruQ0cQNF1HA/RMhrW0xt34PVQ5RVpOMTR0lTtRmYh8H1iQn4el+EkeejiHM1+eJzw7Q/jW7H/QDK7GNxAGro7g9/dxoTfAxe9H+W35H2IpmduixHcTM0QVjdWUgtvlxFhYzDOlFTxmtvGer5vhXxcI3ZznjrhCePlv9hx4HeGn0CR3P/6A4OEj9AfmuPVnmqWVKNd++R0dkGSVtKLicjqpqKxix6EaXmmooXz7i5w97+diYJBCq42SLeXYny9DGBy5wnTtdrqLSikyf8PbxwIk4ktMTUxlX8loGqqq4Ha6MVkKyPU+wFNvPUjLcYGBwWZ6evrJfSSHoicKKS0pQejr7ab30/e54PXi813m3Fdfc+nbfsbHglmgqqoo6vpCFwcb6/AOdPLFpc+5EviQ6es/4P3kDKbHTVgsFux2O8LC/AKT09cZmwjR19tDODzLH3PzJBKJ+wszmSzU4XBQua0S5y4npz86TUdHJ7W1Dezbuxez2YzBYMBqtSJEIhHWkklWRJG5uXnWezwRZ3V1FVEUs17Pfr+fpqYmWjwtdPm78Pk+4+TJZjweD21tbbS2ttLe3s6/vPqBOBKt6pQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dc3f89dc316858dad848ddba49ed8985/e4706/ioBroker-EN_05.avif 230w", "/en/static/dc3f89dc316858dad848ddba49ed8985/d1af7/ioBroker-EN_05.avif 460w", "/en/static/dc3f89dc316858dad848ddba49ed8985/7f308/ioBroker-EN_05.avif 920w", "/en/static/dc3f89dc316858dad848ddba49ed8985/e1c99/ioBroker-EN_05.avif 1380w", "/en/static/dc3f89dc316858dad848ddba49ed8985/2babf/ioBroker-EN_05.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dc3f89dc316858dad848ddba49ed8985/a0b58/ioBroker-EN_05.webp 230w", "/en/static/dc3f89dc316858dad848ddba49ed8985/bc10c/ioBroker-EN_05.webp 460w", "/en/static/dc3f89dc316858dad848ddba49ed8985/966d8/ioBroker-EN_05.webp 920w", "/en/static/dc3f89dc316858dad848ddba49ed8985/445df/ioBroker-EN_05.webp 1380w", "/en/static/dc3f89dc316858dad848ddba49ed8985/fad48/ioBroker-EN_05.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dc3f89dc316858dad848ddba49ed8985/81c8e/ioBroker-EN_05.png 230w", "/en/static/dc3f89dc316858dad848ddba49ed8985/08a84/ioBroker-EN_05.png 460w", "/en/static/dc3f89dc316858dad848ddba49ed8985/c0255/ioBroker-EN_05.png 920w", "/en/static/dc3f89dc316858dad848ddba49ed8985/b1001/ioBroker-EN_05.png 1380w", "/en/static/dc3f89dc316858dad848ddba49ed8985/29007/ioBroker-EN_05.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dc3f89dc316858dad848ddba49ed8985/c0255/ioBroker-EN_05.png",
              "alt": "IO Broker",
              "title": "IO Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/area1/sensitivity     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` - `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"100"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area2/sensitivity     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` - `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"100"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area3/sensitivity     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` - `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"100"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area4/sensitivity     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` - `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"100"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "6-turn-off-all-cameras-when-you-arrive-home",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#6-turn-off-all-cameras-when-you-arrive-home",
        "aria-label": "6 turn off all cameras when you arrive home permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`6. Turn off all Cameras when you arrive home`}</h3>
    <p>{`Either schedule or manually disable all alarm detection areas and the PIR sensor, if you do not want to trigger an alarm:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f501ca8e63f896ec5a14643fa27bef47/29007/ioBroker-EN_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACQUlEQVQoz3WSy27TQBSG/Qp9CJCQKkoXiBUSYssGid6okOBxCq/AAtgggRDlsoFV27TQFiVpKVWgQJoLTeI4ie2xY8cej/2hcdKChBjp93iOjv/LGRvG6z4X1h0uFVxmNlxmCy7n1hyubLnMFHzOr/1gtrDDxY0G0+se0xsOMwWXy5suU+8GTL23Md72MVZ7GKt9jOvbgvmSx3JJsFj0WC57zBU9FksaAbeKde6USyyU28ztRSwUXW7uOiztBdzeD7i373NjV3D1g+DaR4HBZEkgAhL+s+KEwO4hrC5e10RYJirw/2kzZAZ1EfL8xUuePH5E6ahKCGRZhkwU/cGARrPBr5MTLKvH5s42D189o2V26Jhdao0GpmURRBFKE+rH12aLu0vz3F9ZodUxsRUoGVOv17EsiziOc/U0TTmoHFA5/nbmKFEKx3Go1Wr4vo+RAp0g5nNph079OwOZESQpZCnC8/5kyTL6MmMIeYJenOa1HIDneUgpMXQhycBNoRNKRKzO3LhCjLmA1ijlaKg4FAn7ruTIV3ktm+gJIUiSZHwptjfi02GbZqAQybhFpSnD4ZBms8nP4xq+Ansk8aXCSzI8BT2d7MshQRDkcccOgd1KlwdPy0RJippIaoe6sdVqY5rmWETGZEqhpBz3JJJq9TifsRbPHR7UBNsVizdbVaI4yWen3WnCXq+Xw7ZtXNfNY+lzu93JZ2Y7DoPBIL8UXdfERhglBKOYMJL5b5JOyDSiKMpdhmGY76f4+3z6rnf9zW8RCCOQ9+i7gwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f501ca8e63f896ec5a14643fa27bef47/e4706/ioBroker-EN_06.avif 230w", "/en/static/f501ca8e63f896ec5a14643fa27bef47/d1af7/ioBroker-EN_06.avif 460w", "/en/static/f501ca8e63f896ec5a14643fa27bef47/7f308/ioBroker-EN_06.avif 920w", "/en/static/f501ca8e63f896ec5a14643fa27bef47/e1c99/ioBroker-EN_06.avif 1380w", "/en/static/f501ca8e63f896ec5a14643fa27bef47/2babf/ioBroker-EN_06.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f501ca8e63f896ec5a14643fa27bef47/a0b58/ioBroker-EN_06.webp 230w", "/en/static/f501ca8e63f896ec5a14643fa27bef47/bc10c/ioBroker-EN_06.webp 460w", "/en/static/f501ca8e63f896ec5a14643fa27bef47/966d8/ioBroker-EN_06.webp 920w", "/en/static/f501ca8e63f896ec5a14643fa27bef47/445df/ioBroker-EN_06.webp 1380w", "/en/static/f501ca8e63f896ec5a14643fa27bef47/fad48/ioBroker-EN_06.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f501ca8e63f896ec5a14643fa27bef47/81c8e/ioBroker-EN_06.png 230w", "/en/static/f501ca8e63f896ec5a14643fa27bef47/08a84/ioBroker-EN_06.png 460w", "/en/static/f501ca8e63f896ec5a14643fa27bef47/c0255/ioBroker-EN_06.png 920w", "/en/static/f501ca8e63f896ec5a14643fa27bef47/b1001/ioBroker-EN_06.png 1380w", "/en/static/f501ca8e63f896ec5a14643fa27bef47/29007/ioBroker-EN_06.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f501ca8e63f896ec5a14643fa27bef47/c0255/ioBroker-EN_06.png",
              "alt": "IO Broker",
              "title": "IO Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/area1/enable          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area2/enable          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area3/enable          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area4/enable          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/actions/pir/enable    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "7-use-external-sensors-to-turn-your-camera-when-motion-is-detected",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#7-use-external-sensors-to-turn-your-camera-when-motion-is-detected",
        "aria-label": "7 use external sensors to turn your camera when motion is detected permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`7. Use external Sensors to turn your Camera when Motion is detected`}</h3>
    <p>{`Let your camera move between positions depending on system variables in home automation system. For example - when the door sensor is tripped, look to the door, when the window sensor is triggered turn towards the window and when the external motion sensor detects a person, turn to the kitchen door:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bea55130dc33548c648da30efc624894/29007/ioBroker-EN_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAClUlEQVQozy2Oy25bVRiFz6CPwIRJB4iqDKoOkBiC1AuolTropDPECyDR90CMmAKDjqpOSEBJK6WKbXKx40uInTiOsZs4dnLic9lnn7v3udj+kO3+0tIvrfUtaWmfbEm+KLrcKbjc/8flbkFyZymXL3c8Pn0vub3t8tWux2fbLp8XXO6VFn/Rk9wtrrxbmw7aukB7UPH5thryuBbxXT3m6WHCs38THlQCHi2zgB+OQr4/Cvmm7POw4i/ZBfe0oXhYDXnWiHhSDfh630NjPiX1XfTzHlngctyss7a1DtOE5c2nMMshz4DZylMx1eoeb4ubOPqIWIpVNs/R9LHgWoZcyIi24VI5OqT+X4fLyRwvB1NIoskEw5JcWx4RcGqHFOtV2oM+XSdmKEOE5yM9Hy2qHZCXCsBiSfJxxYx5rpjlilSFZElM6EtUIJml4cf18xU7S2GekScxySRES0p/w6+/kLfKpKKD0FtExjGptVJmNVF2l0QOyZwBqXNJZnfwxsdLrZjWijPbaFm9RPjXn3j7JbA66OM2gdEiE13S3hvy/Zco/YAksMkDhzz2mMoe1k0Lx2gzE23ERYXJ4c+o4Taa6u5jNd5inhbIzRb93iFidETmnpOe/sZ06zmJfkBkdIkvK0TDGpnR5HrQZNhvQNijuPGK3usXzPUCWnS6SdJcIz/bwPqwQ7ne4OTshHhQJj35nXT3RyZ6hbi3g2q8QZ2sE4zKtM867NVqJKMyjXd/cPXuJ7LhFpoSfRJ5QSr7TMIRN1Ji+ZIsHJJFV6RKR8VjlByQynNS+QEVDhlLG9P3yKMRgXNOOrlCRVdopmNiSwtLWtiOgbB1hLjBcsyVhIFwLIQrsKS55Ba+sG8QQsdadJZdE9Me8z+XOgp9TV7S+AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bea55130dc33548c648da30efc624894/e4706/ioBroker-EN_07.avif 230w", "/en/static/bea55130dc33548c648da30efc624894/d1af7/ioBroker-EN_07.avif 460w", "/en/static/bea55130dc33548c648da30efc624894/7f308/ioBroker-EN_07.avif 920w", "/en/static/bea55130dc33548c648da30efc624894/e1c99/ioBroker-EN_07.avif 1380w", "/en/static/bea55130dc33548c648da30efc624894/2babf/ioBroker-EN_07.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bea55130dc33548c648da30efc624894/a0b58/ioBroker-EN_07.webp 230w", "/en/static/bea55130dc33548c648da30efc624894/bc10c/ioBroker-EN_07.webp 460w", "/en/static/bea55130dc33548c648da30efc624894/966d8/ioBroker-EN_07.webp 920w", "/en/static/bea55130dc33548c648da30efc624894/445df/ioBroker-EN_07.webp 1380w", "/en/static/bea55130dc33548c648da30efc624894/fad48/ioBroker-EN_07.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bea55130dc33548c648da30efc624894/81c8e/ioBroker-EN_07.png 230w", "/en/static/bea55130dc33548c648da30efc624894/08a84/ioBroker-EN_07.png 460w", "/en/static/bea55130dc33548c648da30efc624894/c0255/ioBroker-EN_07.png 920w", "/en/static/bea55130dc33548c648da30efc624894/b1001/ioBroker-EN_07.png 1380w", "/en/static/bea55130dc33548c648da30efc624894/29007/ioBroker-EN_07.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bea55130dc33548c648da30efc624894/c0255/ioBroker-EN_07.png",
              "alt": "IO Broker",
              "title": "IO Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`features/ptz/preset     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` - `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"7"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`This can be combined well with the parking position in the camera - the camera then automatically returns to the standard position after x minutes.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "8-use-external-sensors-to-trigger-alarm-recordings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#8-use-external-sensors-to-trigger-alarm-recordings",
        "aria-label": "8 use external sensors to trigger alarm recordings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`8. Use external Sensors to trigger Alarm Recordings`}</h3>
    <p>{`When you set up your camera to turn to a position when triggered by an external sensor, you probably want to start an alarm recording on the internal SD card to catch the intruder. This can be done by starting the manual recording through your home automation system and after a delay following up the stop command. Due to adjustable delay, the length of the alarm video is freely selectable (15s - 60s):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d31dbe8eadbca5712f77268d11ea990b/29007/ioBroker-EN_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACH0lEQVQoz12P227TQBCG/XK8DO+BEBJ3FfcgblAvKgFXqCBBSyp6CDm4oXFiJ21sJ3bsHNeH9TrJh2ynqWClTzM7M/+/s9put6OgOEopkiQt88eaiGNEFBEEAdPpFKkUjydNE3zPw/d9hBCFCq0U7weiWOK4AfEOZgqWOTgK9G6PNx/e0hxajFKw52uG4QqhtghRPJgcHtHUcoBaDMiXQ7K5Rex1kUEfGZrIoEe+sJhYN9wbNbKFRTw1mNttQlsnCS3SsI+cmaVH4aVJr8ETdfKisfLIRYBauGR+iyxoEwUdlN8o73nQJJ/UyNzzqu8/eWjZtE2FXhbytcMmWbOVEZtojgo7CK/FxNXJpy2k3ySd9dncHqHqL5ChUZnufQ6Gch/j0SVr8ydicMG6f4byfrPy2vTtDpugBSuDm7OPdE+eg/6KNOj+ayi9JhUNZLHBqIbsf0X2Tqvca/Bg1LmonTPuXUDQ4Nund3x5/QxuX5L4nf13m+X2WjL+RUFc4N8Qt0+Ir46JLo9J2yfM3Wt0y+C7bqL/uYLROfXT93R/HLG1PxONryptySVauhGkmwi1FSU5MYq0pMy3gvkiZLFckqRrdqR4wajUbMiedLsqam6SMIhSHLeHO7EYOQbuxMQdm9yP7nDGZlkv4oPdxXZ7+ME944mJ7Rg44345P3y4Y7Zwig0zkjxDZhGZipFSVDGLkDIi3d8Ptf849KRA5Ql/ASkqMkPeK+m2AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d31dbe8eadbca5712f77268d11ea990b/e4706/ioBroker-EN_08.avif 230w", "/en/static/d31dbe8eadbca5712f77268d11ea990b/d1af7/ioBroker-EN_08.avif 460w", "/en/static/d31dbe8eadbca5712f77268d11ea990b/7f308/ioBroker-EN_08.avif 920w", "/en/static/d31dbe8eadbca5712f77268d11ea990b/e1c99/ioBroker-EN_08.avif 1380w", "/en/static/d31dbe8eadbca5712f77268d11ea990b/2babf/ioBroker-EN_08.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d31dbe8eadbca5712f77268d11ea990b/a0b58/ioBroker-EN_08.webp 230w", "/en/static/d31dbe8eadbca5712f77268d11ea990b/bc10c/ioBroker-EN_08.webp 460w", "/en/static/d31dbe8eadbca5712f77268d11ea990b/966d8/ioBroker-EN_08.webp 920w", "/en/static/d31dbe8eadbca5712f77268d11ea990b/445df/ioBroker-EN_08.webp 1380w", "/en/static/d31dbe8eadbca5712f77268d11ea990b/fad48/ioBroker-EN_08.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d31dbe8eadbca5712f77268d11ea990b/81c8e/ioBroker-EN_08.png 230w", "/en/static/d31dbe8eadbca5712f77268d11ea990b/08a84/ioBroker-EN_08.png 460w", "/en/static/d31dbe8eadbca5712f77268d11ea990b/c0255/ioBroker-EN_08.png 920w", "/en/static/d31dbe8eadbca5712f77268d11ea990b/b1001/ioBroker-EN_08.png 1380w", "/en/static/d31dbe8eadbca5712f77268d11ea990b/29007/ioBroker-EN_08.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d31dbe8eadbca5712f77268d11ea990b/c0255/ioBroker-EN_08.png",
              "alt": "IO Broker",
              "title": "IO Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`task/video/rec/start     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"15"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"30"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"45"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"60"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
task/video/rec/stop      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "9-interconnect-your-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#9-interconnect-your-cameras",
        "aria-label": "9 interconnect your cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`9. Interconnect your Cameras`}</h3>
    <p>{`Install your cameras so that they always keep an eye on each other by utilizing the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Features/PTZ/"
      }}>{`Park Position`}</a>{` that always returns your camera to a set position after it was moved. This prevents intruders from tampering with your cameras unseen.`}</p>
    <p>{`When one camera detect a motion you can have it contact your home automation system using your camera's `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Alarm_Server/"
      }}>{`Alarmserver Function`}</a>{`. You smarthome can then send a command to other cameras nearby to turn towards the position where the initial camera detected the intruder and start an alarm recording.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fd6008d44a5dde85d4df0c4886c21d18/29007/ioBroker-EN_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACc0lEQVQoz02SW08TURSF59cAjXSYmXOZTukNKlRGVHphaFEU6o0i8YpArdIXMSoxmvgg6quEf2J8kAcFI//mM+eAxoeVs5Ozztprr32cD7ufOPx1zIO1dUTpLDONOufrM5QvVHnc2+Ln4W/29vb5srfP4dExa90e+ckp5i7PkywtoItlVu/e4+jXMbsfP+Mks3UODn6w+XSLwVSKIBAoIdGh5sXL13z99p2VzjKd5Vu23n65g680SmuklAwMu6z3nlmNZjKLI6RkfWWVxbFxIt9Ha23hummmpmKWO3doNBrUajVud+4Qx+cZcdMopSwv8j2ulcbYWFlFSIUjlSJwXW77PtejiEoUWbKBcZAaGqLZapEkCanUEFIK9On9RBTRzmbtW+G6SK1xpFQIFTIZZS0yWv8TMw68IKBRrTLbaDDimQlC60RJSag157JZYmMik0F4Hk6oJZWcSyU3glISE4E5zQNDSg8McGu2TjI3RySGmS645DMBxohpahuHId7wMHGocM4VApYuNywqhYBAqBNBE/qZM9RKOd68fUUzqVObCGkvLdK6UEDLE1HDE+k0l3IR3e5DnHJOnm5xGVMH4nRU36dSLNB9tsHOux2SepX5+jQP1jZpVmOU8FBK24nySrLRfUS33z3J0PM8C5ONCdzUkxMTvN/dZa09w/bWI3pbffr9PkODg/ZrSRuNQgQB+fEym50WvfttHDve6db+3261WmV7+zk3L0asryzQfdLjxo3rZDIZ8vm8PU3OQghy+RyL0xHt2thfwb9QljA6Okocx5wtj1MqFbm6cIVWq0mpVKJSqVgUi8V/Ls1XCgKfwPf4A64Pn+FdXfMJAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd6008d44a5dde85d4df0c4886c21d18/e4706/ioBroker-EN_11.avif 230w", "/en/static/fd6008d44a5dde85d4df0c4886c21d18/d1af7/ioBroker-EN_11.avif 460w", "/en/static/fd6008d44a5dde85d4df0c4886c21d18/7f308/ioBroker-EN_11.avif 920w", "/en/static/fd6008d44a5dde85d4df0c4886c21d18/e1c99/ioBroker-EN_11.avif 1380w", "/en/static/fd6008d44a5dde85d4df0c4886c21d18/2babf/ioBroker-EN_11.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fd6008d44a5dde85d4df0c4886c21d18/a0b58/ioBroker-EN_11.webp 230w", "/en/static/fd6008d44a5dde85d4df0c4886c21d18/bc10c/ioBroker-EN_11.webp 460w", "/en/static/fd6008d44a5dde85d4df0c4886c21d18/966d8/ioBroker-EN_11.webp 920w", "/en/static/fd6008d44a5dde85d4df0c4886c21d18/445df/ioBroker-EN_11.webp 1380w", "/en/static/fd6008d44a5dde85d4df0c4886c21d18/fad48/ioBroker-EN_11.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd6008d44a5dde85d4df0c4886c21d18/81c8e/ioBroker-EN_11.png 230w", "/en/static/fd6008d44a5dde85d4df0c4886c21d18/08a84/ioBroker-EN_11.png 460w", "/en/static/fd6008d44a5dde85d4df0c4886c21d18/c0255/ioBroker-EN_11.png 920w", "/en/static/fd6008d44a5dde85d4df0c4886c21d18/b1001/ioBroker-EN_11.png 1380w", "/en/static/fd6008d44a5dde85d4df0c4886c21d18/29007/ioBroker-EN_11.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fd6008d44a5dde85d4df0c4886c21d18/c0255/ioBroker-EN_11.png",
              "alt": "ioBroker",
              "title": "ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`features/ptz/preset     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` - `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"7"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

task/video/rec/start     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"15"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"30"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"45"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"60"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
task/video/rec/stop      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`In this example both cameras look at each other - and keep an eye on what is happening behind the other camera. This position is stored as `}<strong parentName="p">{`Position 1`}</strong>{` (`}<em parentName="p">{`number=0`}</em>{`) and set to be the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Features/PTZ/"
      }}>{`Park Position`}</a>{`. When the left camera detects something, the right one turns around towards the potential intruder - stored as `}<strong parentName="p">{`Position 2-8`}</strong>{` (`}<em parentName="p">{`number=1-7`}</em>{`) - and starts a recording. And vice versa...`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "10-scan-for-noise-sources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#10-scan-for-noise-sources",
        "aria-label": "10 scan for noise sources permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`10. Scan for Noise Sources`}</h3>
    <p>{`For example, when triggering the audio detection on your camera, you probably do not know the location of the source of the noise. Here you can trigger a horizontal (hscan) or vertical (vscan) search scan. This must then be manually stopped again with the desired delay:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b6e50f76e107ce9e42d021032118f8e3/29007/ioBroker-EN_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPElEQVQoz42TS2sTYRSG56+58ifoT3DvwoXgTgTduKntWhTUjVXUojS9Wps2SZM0qW0ukzTJTJJJ0uYyt++SmUcyLaJgwQMPh+/lnAfO4jPiOOYmiGNcd0YYBGghcV0XrRWLiuOIOLrmjx2D/6hmp8OnvS28MLyW3TxrqEkDNW6gJk3kRR0xqCJHV12NzSTb3vjAWuod094JenKOCix0s4AupdFmAW2VUZdm4jBEP09CL4e6qKKmfbQ7RI1txKDMpLGH1cpjOVUYHSO7WURlB3m8hvz4HLm6RFhKXTmcPIYcHCMHpSTQ0xbzYEYkPOb+GHVZpVNaJ1PcpVw5oHr4mVnvCJ16j95bgdU7RHuPiX0T6RRZuBKhcI6RwxJB+4BZbRe38YNZfZegmyO0s2RL+/w8yzBu7uNaOXTmG/7mEo3l25Te3KOwv8Z8VEY4xcXJBRZIp5CcLWopZG0dYW4gnTyefcR5/ZSd7R169UPmvUOEXUB+X+Fk+RZvn97l4YP7eFYW5RQwgm6asJvG72fwGxv4+6/xc6t46VcIa5dOp0iuabNZNilVi6haitBKE5x+gbOXZL++4NmTR4zMLYSTwQjnLoF20ZGLjj0U4W8Wb6FmDAYO0+mUQMzQeKjIRcY+ETFW75yaWWZ4aRPhY/TDgG4o6PVNmq1TnEGTjlWlbVWwujXanQrOsEXbqtGxqwlWt45l17C7C+pIPUZHs6Qb4VwSzBVSevjBFKUDhPT+Qio/4V95KBa/J2AeiYRfawUs1jY6eGAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b6e50f76e107ce9e42d021032118f8e3/e4706/ioBroker-EN_09.avif 230w", "/en/static/b6e50f76e107ce9e42d021032118f8e3/d1af7/ioBroker-EN_09.avif 460w", "/en/static/b6e50f76e107ce9e42d021032118f8e3/7f308/ioBroker-EN_09.avif 920w", "/en/static/b6e50f76e107ce9e42d021032118f8e3/e1c99/ioBroker-EN_09.avif 1380w", "/en/static/b6e50f76e107ce9e42d021032118f8e3/2babf/ioBroker-EN_09.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b6e50f76e107ce9e42d021032118f8e3/a0b58/ioBroker-EN_09.webp 230w", "/en/static/b6e50f76e107ce9e42d021032118f8e3/bc10c/ioBroker-EN_09.webp 460w", "/en/static/b6e50f76e107ce9e42d021032118f8e3/966d8/ioBroker-EN_09.webp 920w", "/en/static/b6e50f76e107ce9e42d021032118f8e3/445df/ioBroker-EN_09.webp 1380w", "/en/static/b6e50f76e107ce9e42d021032118f8e3/fad48/ioBroker-EN_09.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b6e50f76e107ce9e42d021032118f8e3/81c8e/ioBroker-EN_09.png 230w", "/en/static/b6e50f76e107ce9e42d021032118f8e3/08a84/ioBroker-EN_09.png 460w", "/en/static/b6e50f76e107ce9e42d021032118f8e3/c0255/ioBroker-EN_09.png 920w", "/en/static/b6e50f76e107ce9e42d021032118f8e3/b1001/ioBroker-EN_09.png 1380w", "/en/static/b6e50f76e107ce9e42d021032118f8e3/29007/ioBroker-EN_09.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b6e50f76e107ce9e42d021032118f8e3/c0255/ioBroker-EN_09.png",
              "alt": "IO Broker",
              "title": "IO Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`features/ptz/panscan      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
features/ptz/tiltscan     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "11-manually-deactivate-all-alarm-functions-for-all-your-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#11-manually-deactivate-all-alarm-functions-for-all-your-cameras",
        "aria-label": "11 manually deactivate all alarm functions for all your cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`11. Manually deactivate all Alarm functions for all your Cameras`}</h3>
    <p>{`If you have activated more than one alarm trigger in your camera - PIR sensor, alarm areas, audio detection, alarm input - you can activate and deactivate them all at once via the alarm schedule without addressing each trigger individually:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ed3b46dec69c2252aaef410431509d2a/29007/ioBroker-EN_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACX0lEQVQoz2WRS09TQRTH78q4MLpQvwCJoq5dKH4BFyaAJn4C48KYuDJ+EBcujSvjI9EVIhGKCD6oFJoAUqBtpLctt/c1M/fV9j5+5t5iNGGSf+acM785c84c7dycw+VFl7EFlwsll4sllzOzNqc+2JyetTk5Y3N+TnJt+TfjpSpjCwZXFiWXSi7jJZexeae4c/ajw4kZG+3md8nt8kiTZcWtVcXEiuLGN4+JFcnVL4K7az73NtpMrm4xXe4xVfaYKismf3pMVwLurPlcXxaFNEg5trIYQi83/vnZcYx4wNBXMBwcBRI0XUUs/FhjpbqNSiCOY/ROl716A8M0GSYpcZJgBAOWKlVK5XXsQUqWZRy0dPb267T0NoM4YZgkaOv1Ax4/uM+n+XmsIQSewnYcLNOi1+sRBAFxmtEwBU8ePeTd2zcF5/s+juNi23bB5X5ejNZRIbVGA8OP0G2BEoLNrU2evnzO5t42ge8jXBc7itlpNOlKj5YjC65Rr/Ps1Qu+VsuEflAk17I0JQKiFJSSRSv7LZ2N/RphnBQ/k4N/uX4GSkrIMlqGSaW2jYj6Bec4DpoTDYkGcRHwlMRQPubI5SBMUEGI6zpFgiRO6KcglUL4AXp/NKlWlOL6QdGJ1rJ8Xi/WiZMMKQRbv3bwg7AADdOi0WwWYF75+6U6NSNA+R613V1cqUaVCVkMx80TDgYxpUqbhhEhhKDT6SClLGRbFoeHhygpaBoBnzc6eGFMGHgjTgiElDi2TbfbHbXcM01My8I0LQzDQNd19HZ7tB/Jys8t+2gfTT9n2v9xuZ0//geWDRhnBAvLFQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed3b46dec69c2252aaef410431509d2a/e4706/ioBroker-EN_10.avif 230w", "/en/static/ed3b46dec69c2252aaef410431509d2a/d1af7/ioBroker-EN_10.avif 460w", "/en/static/ed3b46dec69c2252aaef410431509d2a/7f308/ioBroker-EN_10.avif 920w", "/en/static/ed3b46dec69c2252aaef410431509d2a/e1c99/ioBroker-EN_10.avif 1380w", "/en/static/ed3b46dec69c2252aaef410431509d2a/2babf/ioBroker-EN_10.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ed3b46dec69c2252aaef410431509d2a/a0b58/ioBroker-EN_10.webp 230w", "/en/static/ed3b46dec69c2252aaef410431509d2a/bc10c/ioBroker-EN_10.webp 460w", "/en/static/ed3b46dec69c2252aaef410431509d2a/966d8/ioBroker-EN_10.webp 920w", "/en/static/ed3b46dec69c2252aaef410431509d2a/445df/ioBroker-EN_10.webp 1380w", "/en/static/ed3b46dec69c2252aaef410431509d2a/fad48/ioBroker-EN_10.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed3b46dec69c2252aaef410431509d2a/81c8e/ioBroker-EN_10.png 230w", "/en/static/ed3b46dec69c2252aaef410431509d2a/08a84/ioBroker-EN_10.png 460w", "/en/static/ed3b46dec69c2252aaef410431509d2a/c0255/ioBroker-EN_10.png 920w", "/en/static/ed3b46dec69c2252aaef410431509d2a/b1001/ioBroker-EN_10.png 1380w", "/en/static/ed3b46dec69c2252aaef410431509d2a/29007/ioBroker-EN_10.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ed3b46dec69c2252aaef410431509d2a/c0255/ioBroker-EN_10.png",
              "alt": "IO Broker",
              "title": "IO Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/schedule/master/sunday    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` - `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Set the alarm schedule for each day of the week from Monday through Sunday. Each `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`P`}</code>{` is half an hour of the day the alarm schedule is active (`}<em parentName="p">{`48 x 30min = 24h`}</em>{`). An `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`N`}</code>{` equals a 30-minute period in which it is disabled. The schedule activates / deactivates all alarm actions.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "12-switch-between-pir-and-software-motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#12-switch-between-pir-and-software-motion-detection",
        "aria-label": "12 switch between pir and software motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12. Switch between PIR and Software Motion Detection`}</h3>
    <p>{`Most of our new cameras have both an internal, software-based motion detection and a hardware PIR (passive infrared) heat sensor to detect motion of objects that have a different surface temperature as the ambient. In most cases we recommend that you `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Actions/"
      }}>{`link both`}</a>{` as they have different strengths and weaknesses and the coupling can filter many sources of false alerts.`}</p>
    <p>{`But there are situation where one of those two ways to detect motion simply fails and does not provide any filtering effect anymore. Examples are:`}</p>
    <ul>
      <li parentName="ul">{`The PIR is exposed to direct sunlight through most of the day. Shadows and temperature changes trigger regular false alarms.`}</li>
      <li parentName="ul">{`The Motion Detection Areas are constantly triggered at night due to the light from passing cars.`}</li>
      <li parentName="ul">{`etc.`}</li>
    </ul>
    <p>{`We can use our smarthome to automatically deactivate the problematic sensor during times where we know that their accuracy is affected. Since we probably want to use both sensors during times of the day when both work perfectly, we also have to switch the coupling on and off, accordingly.`}</p>
    <p><strong parentName="p">{`Link between PIR and Alarm Areas`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/actions/pir/link	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><strong parentName="p">{`Alarm Areas Activation / Deactivation`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/area1/enable          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area2/enable          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area3/enable          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area4/enable          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><strong parentName="p">{`PIR Activation / Deactivation`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/actions/pir/enable    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      